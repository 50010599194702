import React from 'react';
import "./AboutUs.scss"

AboutUs.propTypes = {};

function AboutUs() {
    return (
        <section className="about-us" id="about-us">
            <div className="about-us__inner wrapper">
                <h2 className="section-heading about-us__title">About us</h2>
                <div className="about-us__description">
                    <p className="about-us__text">
                        We are a bold studio!
                        A team of creative developers who will solve any problem in business. We specialize in the
                        development of websites and mobile applications, as well as redesign and redesign of existing
                        sites with promotion in social networks and attracting customers. Our team includes: designers,
                        developers, testers, SMMs. We work with any country and with any business problem.
                    </p>
                    <p className="about-us__text">
                        Our websites are ordered on a turnkey basis so that the business can develop and realize its
                        potential in different areas.
                        We implement websites and mobile applications very quickly and efficiently. Our goal is to solve
                        a business problem! If you want to order a website or mobile application, you need to contact us
                        using the form below and we will contact you shortly to clarify the details.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;