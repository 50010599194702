import React from 'react';

import "./Services.scss";
import manWithSpyglassIcon from "../../assets/img/svg/man-with-spyglass.svg";

Services.propTypes = {};

function Services(props) {
    return (
        <section className="services" id="services">
            <div className="services__inner wrapper">
                <figure className="services__image">
                    <img src={manWithSpyglassIcon}
                         alt="A man is looking through a lot of pages using spyglass"/>
                </figure>
                <div className="services__details">
                    <h2 className="section-heading services__heading">What we can</h2>
                    <p className="section-subheading services__subheading">You can find the best solutions that
                        are
                        suitable for your
                        needs</p>
                    <ul className="services__list">
                        <li className="services__item">UI/UX Design</li>
                        <li className="services__item">Development</li>
                        <li className="services__item">Marketing and strategy</li>
                        <li className="services__item">SMM (Facebook, Instagram)</li>
                        <li className="services__item">Support</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Services;