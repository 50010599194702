import React from "react";
import Header from "../Header/Header";
import { AnchorLink } from "gatsby-plugin-anchor-links";


import "./Hero.scss";
import womanInGearIcon from "../../assets/img/svg/woman-in-gear.svg";

Hero.propTypes = {};

function Hero() {
    return (
        <div className="hero">
            <Header/>
            <section className="hero-body">
                <div className="hero-body__inner wrapper">
                    <div className="hero-body__details">
                        <h1 className="hero-body__heading">We are <br/> bold
                                                           studio</h1>
                        <ul className="hero-body__list">
                            <li className="hero-body__item">Creating a websites
                                                            and mobile
                                                            applications
                            </li>
                            <li className="hero-body__item">Your unique studio
                            </li>
                        </ul>
                        <AnchorLink
                            to="#contact-us"
                            title="Hire us"
                            className="hire-us-link link hero-body__link"
                        >
                            Hire us
                        </AnchorLink>
                    </div>
                    <figure className="hero-body__figure">
                        <img src={ womanInGearIcon }
                             alt="A woman is sitting in the gear and working on her laptop"/>
                    </figure>
                </div>
            </section>
        </div>
    );
}

export default Hero;