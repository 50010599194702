import React from 'react';

import "./Map.scss";
import mapIcon from "../../assets/img/svg/world-map.svg"

Map.propTypes = {};

function Map() {
    return (
        <section className="map">
            <div className="map__inner wrapper">
                <h2 className="section-heading map__heading">World wide service</h2>
                <p className="section-subheading map__subheading">We understand clients from each corner of this
                    planet</p>
                <div className="map__image">
                    <img src={mapIcon} alt="World map"/>
                </div>
            </div>
        </section>
    );
}

export default Map;