import React from 'react';
import PortfolioCarousel from "../PortfolioCarousel/PortfolioCarousel";

import "./Portfolio.scss"
Portfolio.propTypes = {};

function Portfolio() {
    return (
        <section className="portfolio">
            <div className="portfolio__inner">
                <h2 className="portfolio__heading section-heading">Our portfolio</h2>
                <p className="portfolio__subheading section-subheading">The websites created by our web studio</p>
                <PortfolioCarousel/>
            </div>
        </section>
    );
}

export default Portfolio;