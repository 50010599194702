import React from 'react';
import Carousel from "../Carousel/Carousel";
import CarouselNavigationContent from "../CarouselNavigationContent/CarouselNavigationContent";

import "./Reviews.scss"
import authorImg from "../../assets/img/author__img.png"
import authorImg2 from "../../assets/img/author__img2.png"
import authorImg3 from "../../assets/img/author__img3.png"

Reviews.propTypes = {};

const DATA = [
    {
        author: {
            name: "Manoj Kohli",
            origin: "Softbank India",
            image: authorImg,
        },
        text: `“Wow ... I am very happy to cooperate with BOLD
                studio, did the guys turn out to be very cool professionals?
                more than I expected. BOLD studio is always the best”`,
    },
    {
        author: {
            name: "Kateryna Druzhynina",
            origin: "Upstart, Armenia",
            image: authorImg2,
        },
        text: `"The guys know their business, I ordered a multilingual
                site from them, with adaptive for mobile. 
                The site was made on time with a beautiful and thoughtful design.
                Recommend"`,
    },
    {
        author: {
            name: "Isamu Takahashi",
            origin: "Osaka, Japan",
            image: authorImg3,
        },
        text: `This is very unusual for my business, which
               currently requires thoughtful design and
               competent copywriting.
                This studio did even better than expected.`,
    },
]

function Reviews() {

    const swiperSlides = DATA.map(({author, text}, index) => {
        return (
            <div className="review" key={`${Date.now()}-${index}`}>
                <div className="review__author">
                    <picture className="review__image">
                        <img src={author.image} alt="Author"/>
                    </picture>
                    <div className="review__credentials">
                        <p className="review__author-name">{author.name}</p>
                        <p className="review__author-origin">{author.origin}</p>
                    </div>
                </div>
                <q className="review__text">{text}</q>
            </div>
        )
    });

    const swiperConfig = {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 50,
        pagination: {
            dynamicBullets: true,
            dynamicMainBullets: 4,
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: (index, className) => {
                return `<span class="${className}"></span>`
            }
        },
        navigation: {
            prevEl: ".swiper__btn--prev",
            nextEl: ".swiper__btn--next",
        }
    }

    return (
        <section className="reviews">
            <div className="reviews__inner">
                <h2 className="section-heading reviews__heading">What they say about us</h2>
                <p className="section-subheading reviews__subheading">These are the stories of our customers</p>

                <Carousel
                    slides={swiperSlides}
                    swiperChildren={<CarouselNavigationContent/>}
                    swiperProps={swiperConfig}/>
            </div>
        </section>
    );
}

export default Reviews;