import React from 'react';
import PropTypes from 'prop-types';

import forYouIcon from "../../assets/img/svg/for-you.svg"
import crossIcon from "../../assets/img/svg/cross.svg"
import "./Modal.scss"

Modal.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

// this event will be triggered if the user has clicked outside of a modal
const MODAL_EVENT = "clickaway"

function Modal({text, onClick}) {
    const handleModalClick = (e) => {
        onClick(e, MODAL_EVENT);
    }

    const handleModalBtnClick = (e) => {
        e.stopPropagation();
        onClick(e);
    }

    return (
        <div className="modal" onClick={handleModalClick}>
            <div className="modal__body">
                <button className="modal__cross-btn" onClick={handleModalBtnClick}>
                    <img src={crossIcon} alt="Cross button"/>
                </button>
                <figure className="modal__icon">
                    <img src={forYouIcon} alt="Thank you"/>
                </figure>
                <p className="modal__text">{text}</p>
                <button className="modal__submit-btn">OK</button>
            </div>
        </div>
    );
}

export default Modal;