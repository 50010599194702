import React from 'react';

import "./Advantages.scss";
import serverIcon from "../../assets/img/svg/server.svg";
import clockIcon from "../../assets/img/svg/clock.svg";
import userIcon from "../../assets/img/svg/user.svg"

Advantages.propTypes = {};

function Advantages() {
    return (
        <section className="advantages">
            <div className="advantages__inner wrapper">
                <ul className="advantages__list">
                    <li className="advantages__item advantage">
                        <div className="advantage__icon">
                            <img src={serverIcon} alt="Server"/>
                        </div>
                        <div className="advantage__details">
                            <p className="advantage__text">100+</p>
                            <p className="advantage__text">Projects</p>
                        </div>
                    </li>
                    <li className="advantages__item advantage">
                        <div className="advantage__icon">
                            <img src={clockIcon} alt="Clock"/>
                        </div>
                        <div className="advantage__details">
                            <p className="advantage__text">2+</p>
                            <p className="advantage__text">Years</p>
                        </div>
                    </li>
                    <li className="advantages__item advantage">
                        <div className="advantage__icon">
                            <img src={userIcon} alt="User icon"/>
                        </div>
                        <div className="advantage__details">
                            <p className="advantage__text">30+</p>
                            <p className="advantage__text">Clients</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default Advantages;