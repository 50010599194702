import * as React from "react"
import "../assets/scss/index.scss"

import Hero from "../components/Hero/Hero";
import Advantages from "../components/Advantages/Advantages";
import Services from "../components/Services/Services";
import AboutUs from "../components/AboutUs/AboutUs";
import Portfolio from "../components/Portfolio/Portfolio";
import Map from "../components/Map/Map";
import Reviews from "../components/Reviews/Reviews";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";
import Modal from "../components/Modal/Modal";
import {useState} from "react";

const IndexPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClick = (event, type) => {
        setIsModalOpen(false);
    }
    return (
        <>
            <title>Bold</title>
            <Hero/>
            <main className="main-content">
                <Advantages/>
                <Services/>
                <AboutUs/>
                <Portfolio/>
                <Map/>
                <Reviews/>
                <ContactUs/>
                {
                    isModalOpen && (
                        <Modal
                            onClick={handleClick}
                            text="Lorem ipsum dolor sit amet consectetur adipiscing elit posuere curabitur lacus lacinia arcu ultrices vivamus praesent, senectus habitant justo eu magnis ut iaculis egestas auctor litora sagittis aptent laoreet sociis."
                        />
                    )
                }
            </main>
            <Footer/>
        </>
    )
}

export default IndexPage
